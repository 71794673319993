.group{
color: black !important;
border-color: #B1DCE0 !important;
    
}
.group::before{
    border-color: #B1DCE0 !important;
}
.group::after{
    border-color: #B1DCE0 !important;
}